<script setup lang="ts">
import CovidIcon from '~/assets/icons/topics/covid.svg'

defineProps<{
  title?: string
  isLoading?: boolean
  error?: any | undefined
}>()

const { isInstitution, formattedFullname } = useAuthorization()
</script>

<template>
  <div class="min-h-screen grow bg-gray-100">
    <div class="PagePadding flex flex-col 2xl:flex-row 2xl:items-center gap-4 2xl:justify-between py-5 bg-white shadow-base z-10">
      <h1 class="flex items-center gap-3">
        <div class="shrink-0 grid justify-center items-center p-2 bg-beige rounded h-[45px] w-[45px]">
          <slot v-if="$slots.icon" name="icon" />
          <CovidIcon v-else />
        </div>
        <ClientOnly>
          <span v-if="error">
            {{ $t('app.error') }}
          </span>

          <n-skeleton v-else-if="isLoading" :width="300" :sharp="false" size="medium" />

          <div v-else>
            <div v-if="$slots.title && !isLoading">
              <slot name="title" />
            </div>

            <span v-else>
              {{ title }}
            </span>
          </div>

          <template #fallback>
            <n-skeleton :width="300" :sharp="false" size="medium" />
          </template>
        </ClientOnly>
      </h1>
      <ClientOnly>
        <template v-if="$slots['header-extra']">
          <n-divider class="!my-0 2xl:hidden" />
          <div v-if="!isLoading && !error" class="flex justify-between items-center gap-1">
            <slot name="header-extra" />
          </div>
        </template>
      </ClientOnly>
    </div>

    <div v-if="isInstitution" class="PagePadding w-full mt-5">
      <n-alert
        :title="`${$t('alert.title.notice')}:`"
        type="warning"
        :show-icon="false"
      >
        {{ $t('institutions.loggedIn.banner', { name: formattedFullname }) }}
      </n-alert>
    </div>

    <div class="PagePadding mt-5 mb-12 pb-6 lg:mb-0 lg:pb-10">
      <ClientOnly>
        <TheDataCard v-if="error">
          <h2>
            {{ $t('notifications_error_tryAgain') }}
          </h2>
        </TheDataCard>
        <TheLoader v-else-if="isLoading" />
        <slot v-else />

        <template #fallback>
          <TheLoader />
        </template>
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped>
.PagePadding {
  @apply px-4 md:px-7;
}
</style>
